import React from 'react'
import styles from "./Interview.module.css"
import Confetti from 'react-confetti';
import DOMPurify from "dompurify";

const DEFAULT_INTERVIEW_OUTRO_HTML = "<h2>Thank you!</h2> <p>You completed all video questions for this interview!</p>"

const InterviewOutro = ({positionTexts}) => {

    return (<div className={styles.interview_page}>
        <div className={styles.final_message}>
            <Confetti/>
            <div
                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(positionTexts?.['interviewOutro'] || DEFAULT_INTERVIEW_OUTRO_HTML)}}></div>
        </div>

    </div>)
}

export default InterviewOutro
