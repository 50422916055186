import styles from "./Comparisons.module.css";
import ComparisonTable from '../components/MainContent/ComparisonTable'
import Header from '../components/Header/Header';
import LeftSidebar from '../components/Sidebar/Sidebar';
import FloatingButton from "../components/General/FloatingButton";
import {useContext, useEffect, useState} from "react";
import PositionContext from "../contexts/position";
import Popup from "../components/Popup/Popup";
import {makePostRequest, submitAIQuestion} from "../api/submit"
import {API, BACKEND} from "../constants";
import {formatStr} from "../utils/common";
import Joyride from "react-joyride";


const aiResponsePlaceholder = "AI is doing its magic to load the responses..."


const Comparisons = () => {
        const {position, data, setData, setUser} = useContext(PositionContext);
        const [candidates, setCandidates] = useState([])
        const [runTips, setRunTips] = useState(false)

        const [selectedCandidates, setSelectedCandidates] = useState([undefined, undefined])

        let fetchQuestions = function (request_body) {
            return makePostRequest(`${BACKEND}${API}/fetch_interview_questions/`, request_body)
                .then(response => {
                    return response;
                })
        };

        let fetchInterviewees = function (request_body) {
            return makePostRequest(`${BACKEND}${API}/fetch_candidates/`, request_body)
                .then(response => {
                    return response;
                })
        };

        let fetchResponses = function (request_body) {
            return makePostRequest(`${BACKEND}${API}/fetch_responses/`, request_body)
                .then(response => {
                    return response;
                })
        };

        let populateTable = function (loadQuestions, loadCandidates) {
            let fetchPromises = {}
            if (loadQuestions) {
                fetchPromises['questions'] = (fetchQuestions({position_id: position}))
            }

            if (loadCandidates) {
                fetchPromises['interviewees'] = (fetchInterviewees({position_id: position}))
            }

            for (let i = 0; i < 2; i++) {
                if (selectedCandidates[i] !== undefined) {
                    fetchPromises[`candidate${i + 1}`] = (fetchResponses({
                        position_id: position,
                        interviewee_id: selectedCandidates[i]
                    }))
                }
            }

            Promise.all(Object.keys(fetchPromises).map(key =>
                fetchPromises[key].then(result => ({key, result}))
            )).then(response => {
                const results = response.reduce((acc, item) => {
                    acc[item.key] = item.result;
                    return acc;
                }, {});
                let _data = {...data} || {};
                if (loadQuestions) {
                    _data = {};
                    let questions = []
                    for (const question of results['questions']['questions']) {
                        if (question.q_type === 'id' || question.q_type === 'qualification') {
                            continue;
                        }
                        questions.push(question);
                    }
                    questions = questions.slice().sort((a, b) => {
                        const typeOrder = {"interview": 1, "roleplay": 2, "ai": 3};
                        if (typeOrder[a.q_type] !== typeOrder[b.q_type]) {
                            return typeOrder[a.q_type] - typeOrder[b.q_type]
                        }
                        return a.q_number - b.q_number
                    });
                    for (const item of questions) {
                        _data[item['q_type']] = _data[item['q_type']] || {}
                        _data[item['q_type']][item['q_number']] = {
                            question: {
                                summaryText: formatStr(item['short_name']) || '',
                                fullText: formatStr(item['prompt']) || '',
                                ...item
                            },
                            response1: {
                                summaryText: '',
                                fullText: ''
                            },
                            response2: {
                                summaryText: '',
                                fullText: ''
                            },
                            hidden: false,
                            isAI: (item['q_type'] === 'ai')
                        }
                    }
                }

                if (loadCandidates) {
                    setCandidates(results['interviewees']['candidates']);
                }

                for (let i = 0; i < 2; i++) {
                    // empty the whole column before adding to it
                    if (selectedCandidates[i] === undefined || results[`candidate${i + 1}`] !== undefined) {
                        for (const key in data) {
                            for (const index in data[key]) {
                                data[key][index][`response${i + 1}`] = {
                                    summaryText: '',
                                    fullText: '',
                                }
                            }
                        }
                    }
                    if (results[`candidate${i + 1}`] !== undefined) {
                        for (const item of results[`candidate${i + 1}`]['interview_responses']) {
                            let formattedFullResponse = formatStr(item.response)
                            if (item.question_type === 'roleplay' && formattedFullResponse !== '') {
                                const userRole = _data[item.question_type][item.question_number]['question']['user_role']
                                const botRole = _data[item.question_type][item.question_number]['question']['bot_role']
                                const regexUserRole = new RegExp(`${userRole}:`, 'g')
                                const regexBotRole = new RegExp(`${botRole}:`, 'g')
                                formattedFullResponse = `${userRole}:` + formattedFullResponse.split(`${userRole}:`).slice(1).join(`${userRole}:`)
                                formattedFullResponse = formattedFullResponse.replace(regexUserRole, `<b>${userRole}:</b>`)
                                formattedFullResponse = formattedFullResponse.replace(regexBotRole, `<b>${botRole}:</b>`)
                            }
                            let formattedSummary = formatStr(item.summary);
                            const boldKeywords = ["Strengths", "Weaknesses", "Summary", "Tugevused", "Nõrkused", "Kokkuvõte"];
                            formattedSummary = formattedSummary.replace(new RegExp(`(${boldKeywords.join('|')}): `, 'g'), '<strong>$1: </strong>');
                            formattedSummary = formattedSummary.replaceAll('<br><br>', '<br>').replaceAll('<br>', '<br> <br>')
                            _data[item.question_type][item.question_number][`response${i + 1}`] = {
                                summaryText: formattedSummary,
                                fullText: formattedFullResponse,
                            }
                        }
                    }
                }
                setData(_data)
            })
        }

        useEffect(() => {
            setRunTips(true)
        }, [])

        useEffect(() => {
            console.log(`CURRENT POSITION: ${position}`)
            if (position === undefined) {
                setData([])
                setCandidates([])
                setSelectedCandidates([undefined, undefined])
                return;
            }
            populateTable(true, true);
        }, [position])

        useEffect(() => {
            console.log("selectedCandidates: ", selectedCandidates)
            populateTable(false, false)
        }, [selectedCandidates])

        let updateCandidateAnswers = function (e, dropdownId) {
            const dropdownIndex = dropdownId === 'candidate1' ? 0 : 1;
            let _selectedCandidates = [...selectedCandidates]
            _selectedCandidates[dropdownIndex] = e ? e.value : undefined;
            setSelectedCandidates(_selectedCandidates)
        }

        const flattenData = () => {
            let tableData = []
            for (const key in data) {
                for (const index in data[key]) {
                    tableData.push(data[key][index]);
                }
            }
            return tableData;
        }


// ------------------ Popup functions ------------------------

        const [popupIsOpen, setIsPopupOpen] = useState(false);
        const [popupInputValue, setPopupInputValue] = useState("");

        function openPopup() {
            setIsPopupOpen(true);
        }

        function closePopup() {
            setIsPopupOpen(false);
        }

        function handlePopupInputChange(e) {
            // if the target is a button, use innerText, otherwise use value
            const newValue = e.target.tagName.toLowerCase() === 'button' ? e.target.innerText : e.target.value;
            setPopupInputValue(newValue);
        }

        function handlePopupSubmit(e) {
            e.preventDefault(); // prevent the page from reloading
            closePopup();
            submitAIQuestion({position_id: position, prompt: popupInputValue}).then(response => {
                setPopupInputValue("")
                populateTable(true, false)
            });
        }

// ----------------- End of Popup functions  ------------------

        return (
            <div className={styles.page}>
                {/*<Joyride*/}
                {/*    steps={[*/}
                {/*        {*/}
                {/*            target: '#menu-item-candidates',*/}
                {/*            content: 'This is my awesome feature 1!',*/}
                {/*            disableBeacon: true,*/}
                {/*        },*/}
                {/*        {*/}
                {/*            target: '#menu-item-comparisons',*/}
                {/*            content: 'This is my awesome feature 2!',*/}
                {/*            disableBeacon: true,*/}
                {/*        },*/}
                {/*        {*/}
                {/*            target: '#menu-item-new_interview',*/}
                {/*            content: 'This is my awesome feature 3!',*/}
                {/*            disableBeacon: true,*/}
                {/*        },*/}
                {/*    ]*/}
                {/*    }*/}
                {/*    debug={true}*/}
                {/*    run={runTips}*/}
                {/*    escapeKey={true}*/}
                {/*/>*/}
                <LeftSidebar/>
                <Header boxData={flattenData(data)}/>
                {position && <>
                    <div className={styles.mainContent}>
                        <ComparisonTable data={flattenData(data)} candidates={candidates}
                                         onDropdownChangeFunc={updateCandidateAnswers}/>
                    </div>
                    {/*<FloatingButton cta={openPopup}/>*/}
                    {/*<Popup*/}
                    {/*    isOpen={popupIsOpen}*/}
                    {/*    onClose={closePopup}*/}
                    {/*    inputValue={popupInputValue}*/}
                    {/*    onInputChange={handlePopupInputChange}*/}
                    {/*    onSubmit={handlePopupSubmit}*/}
                    {/*/>*/}
                </>
                }
            </div>
        );
    }
;

export default Comparisons;
