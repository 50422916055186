import React, {useEffect, useState} from 'react';
import StepZilla from "react-stepzilla";
import styles from "./InterviewWizard.module.css";
import BaseLayout from '../components/Wizard/BaseLayout';
import {TextField, Button, Checkbox} from '@material-ui/core';
import {FormControlLabel, Typography} from '@material-ui/core';
import {makePostRequest} from "../api/submit";
import {useParams} from "react-router-dom";
import {API, BACKEND, TAWK_PROPERTY_ID} from "../constants";
import DOMPurify from "dompurify";
import {createRecommendedGA4Event} from "../api/ga4";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';


const errorMap = {
    200: null,
    409: "This email has already been used. Please, use a different one.",
    500: "Something went wrong. Please, try again in a few minutes or reach out to hello@taita.io",
}

const DEFAULT_POSITION_OUTRO_TEXT = "To begin your video interview, please follow these instructions:<br><b>Understanding the Format:</b><ul><li>You will be asked several questions during this interview.</li><li>Each question will be displayed at the top of the screen.</li></ul><b>Preparation Phase:</b><ul><li>Take your time to think about your response to each question.</li><li>Utilize the provided note field below each question for your personal brainstorming. Remember, the notes are private and not saved or shared.</li></ul><b>Recording Your Answer:</b><ul><li>When ready, click the 'Record' button to start recording your answer.</li><li>Responses typically range from 1 to 3 minutes, varying with the complexity of the question.</li></ul><b>Rerecording Option:</b><ul><li>If you wish to rerecord your answer, you can do so. The allowable number of attempts is displayed in the bottom right corner of the screen.</li></ul><b>Evaluation Process:</b><ul><li>Your video responses will be reviewed both by our AI system and human recruiters.</li><li>Our assessment focuses on your overall fit for the role, not just specific keywords in your answers.</li></ul><b>Support and Feedback:</b><ul><li>For any technical issues or feedback, please reach out to us at hello@taita.io.</li></ul><b>We encourage you to enjoy the process and express yourself freely. Best of luck in your interview!</b>"

const QuestionsComponent = ({questions, answers, setAnswers, isSubmitClicked, errors, apiErrors, handleSubmit}) => {
    const updateAnswers = (id, value) => {
        setAnswers({...answers, [id]: value});
    }

    const handleChange = (id) => (event) => {
        updateAnswers(id, event.target.value)
    };

    const [error, setError] = useState(null); //TODO: Viktor, use serError("Error message") to display error message

    useEffect(() => {
        console.log('bonjour')
    })
    useEffect(() => {
        if (apiErrors.length > 0) {
            const errorCode = apiErrors[0]?._metadata?.status || 200
            setError(errorMap[errorCode])
        } else {
            setError(errorMap[200])
        }
    }, [apiErrors]);

    return (
        <form className={styles.wrapper}>
            {questions.map((question) => (
                <TextField
                    key={question.id}
                    id={question.id}
                    label={question.label}
                    placeholder={question.placeholder}
                    type={question.type}
                    value={answers[question.id]}
                    onChange={handleChange(question.id)}
                    // uncomment to enable formsubmit on enter
                    // onKeyDown={(event) => {
                    //     if (event.key === "Enter") {
                    //         event.preventDefault(); // Prevent default form submission
                    //         handleSubmit(); // Call the handleSubmit function with answers
                    //     }
                    // }}
                    fullWidth
                    margin="normal"
                    required={true}
                    error={isSubmitClicked && errors[question.id] !== undefined}
                    helperText={errors[question.id]}
                />
            ))}
            <FormControlLabel
                required={true}
                control={<Checkbox
                    required={true}
                    onChange={(e) => {
                        updateAnswers("agreement", e.target.checked)
                    }}
                />}
                label={<p>I agree with the <a
                    href={"https://docs.google.com/document/d/e/2PACX-1vScdc6afm4Pztlu6DEpf7YSCShX5nhtk_ebffPe3ZqrNl1OI4bvaV8fGKzvLw_lzdHRGUmemLjmay17/pub"}
                    target="_blank"
                >Privacy Policy</a></p>}

            />
            {isSubmitClicked && errors["agreement"] !== undefined && (
                <Typography variant="body2" className={styles.error_text} color="error">
                    {errors["agreement"]}
                </Typography>
            )}


            {error && <p className={styles.error_text}>{error}</p>}
        </form>
    );
};


const InterviewWizard = () => {
    // questions to pass to steps with a form
    const questions = [
        {"id": "firstName", "label": "First Name", "placeholder": "Enter your first name", "type": "text"},
        {"id": "lastName", "label": "Last Name", "placeholder": "Enter your last name", "type": "text"},
        {"id": "email", "label": "Email", "placeholder": "Enter your email", "type": "email"},
    ];
    const {positionAccessCode} = useParams()
    const [positionInfo, setPositionInfo] = useState({});
    const [interviewLink, setInterviewLink] = useState("");

    useEffect(() => {
        const requestBody = {position_access_code: positionAccessCode}
        createRecommendedGA4Event('open_interview_signup', {
            'label': positionAccessCode,
            'category': 'Candidate Funnel'
        });
        makePostRequest(`${BACKEND}${API}/get_position_info/`, requestBody).then(response => {
            if (response.error === undefined) {
                setPositionInfo(response);
            }
        })
    }, [positionAccessCode])

    const handleFormSubmit = (answers) => {
        return new Promise((resolve, reject) => {
            // If form submission is successful
            if (answers["firstName"] && answers["lastName"] && answers["email"]) {
                const requestBody = {
                    position_access_code: positionAccessCode,
                    data: {
                        email: answers.email,
                        firstName: answers.firstName,
                        lastName: answers.lastName,
                    }
                }
                makePostRequest(`${BACKEND}${API}/process_signup/`, requestBody)
                    .then(response => {
                        if (response.error) {
                            reject(response)
                            return
                        }
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })

            } else {
                reject("Form submission failed");
            }
        });
    };

    const Welcome = (props) => (
        <BaseLayout
            step_title={`Welcome to the ${positionInfo.company}'s ${positionInfo.title} interview signup!`}
            content_component={<p
                className={styles.text}
                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(positionInfo?.messages?.['general.intro'])}}></p>}
            onNextClick={() => {
                createRecommendedGA4Event('open_interview_signup_form', {
                    'label': positionAccessCode,
                    'category': 'Candidate Funnel'
                });
                props.jumpToStep(1)
            }}
        />
    );

    const PersonalDetails = (props) => {
        const [answers, setAnswers] = useState(
            questions.reduce((acc, {id}) => ({...acc, [id]: ''}), {})
        );
        const [isSubmitClicked, setIsSubmitClicked] = useState(false);
        const [errors, setErrors] = useState({});
        const [apiErrors, setApiErrors] = useState([]);

        const validate = () => {
            const newErrors = {};
            questions.forEach(({id, type}) => {
                if (!answers[id]) {
                    newErrors[id] = 'This field is required';
                } else if (type === 'email' && !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,12}$/.test(answers[id])) {
                    newErrors[id] = 'Invalid email format';
                }
            });
            if (answers["agreement"] !== true) {
                newErrors["agreement"] = "You must agree to the Privacy Policy";
            }
            setErrors(newErrors);
            return !Object.values(newErrors).length;
        };

        const onNextClick = async () => {
            setIsSubmitClicked(true);
            if (validate()) {
                handleFormSubmit(answers)
                    .then((response) => {
                        createRecommendedGA4Event('success_interview_signup_form', {
                            'label': positionAccessCode,
                            'category': 'Candidate Funnel'
                        });
                        setInterviewLink(response['interviewLink'])
                        props.jumpToStep(1);
                    })
                    .catch((apiErrors) => {
                        createRecommendedGA4Event('error_interview_signup_form', {
                            'label': positionAccessCode,
                            'category': 'Candidate Funnel'
                        });
                        setApiErrors((prevErrors) => ([...prevErrors, apiErrors]));
                    });
            } else {
                createRecommendedGA4Event('invalid_interview_signup_form', {
                    'label': positionAccessCode,
                    'category': 'Candidate Funnel'
                });
            }
        };


        return (
            <BaseLayout
                step_title={"Personal Details"}
                content_component={<QuestionsComponent questions={questions} answers={answers} setAnswers={setAnswers}
                                                       isSubmitClicked={isSubmitClicked} errors={errors}
                                                       apiErrors={apiErrors} handleSubmit={onNextClick}/>}
                onNextClick={onNextClick}
            />

        );
    };


    const VideoInterviewIntro = (props) => (
        <BaseLayout
            step_title={"Welcome to Taita's Video Interview Platform!"}
            content_component={
                <div>
                    <p
                        className={styles.text}
                        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(positionInfo?.messages?.['general.outro'] || DEFAULT_POSITION_OUTRO_TEXT)}}
                    />
                    <div style={{position: 'relative', paddingBottom: '46.875%', height: '0',}}>
                        <iframe
                            src="https://www.loom.com/embed/3fdffb31e2d94cdebec28e76f8682284?sid=680948ff-f239-4b8b-8ac1-43da0d5470e5?hide_owner=true?hide_share=true?hide_title=true?hideEmbedTopBar=true"
                            frameBorder="0"
                            allowFullScreen
                            style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                        />
                    </div>
                </div>
            }
            onNextClick={() => {
                window.location.href = interviewLink
            }}
        />
    );

    const steps = [
        // {name: 'Welcome!', component: <Welcome/>},
        {name: 'Personal Details', component: <PersonalDetails/>},
        {name: 'Video Interview Intro', component: <VideoInterviewIntro/>},
    ];

    return (
        <div className={styles.page}>
            <StepZilla
                steps={steps}
                startAtStep={0}
                showNavigation={false}
                dontValidate={true}
                stepsNavigation={false}
                showSteps={false}
                preventEnterSubmission={true}
            />
        </div>
    )
};

export default InterviewWizard;
