import {
    Routes,
    Route,
    useNavigationType,
    useLocation,
} from "react-router-dom";
import Comparisons from "./pages/Comparisons";
import React, {useEffect} from "react";
import {PositionProvider} from "./contexts/position";
import {RoleplayProvider} from "./contexts/roleplay";
import Roleplay from "./pages/Roleplay";
import Interview from "./pages/Interview/Interview";
import {InterviewProvider} from "./contexts/interview";
import Candidates from "./pages/Candidates/Candidates"
import InterviewWizard from "./pages/InterviewWizard";
import Login from "./pages/Login";
import Register from "./pages/Register";
import InterviewCreation from "./pages/InterviewCreation";
import {InterviewCreationProvider} from "./contexts/interviewCreation";
import {CssBaseline} from "@material-ui/core";
import ReactGA from "react-ga4";
import {GA4, TAWK_PROPERTY_ID} from "./constants";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import InterviewBase from "./pages/Interview/Base";
import {ReactNotifications} from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import {AuthContextProvider} from "./auth/";

ReactGA.initialize(GA4);

function App() {
    const action = useNavigationType();
    const location = useLocation();
    const pathname = location.pathname;
    useEffect(() => {
        if (action !== "POP") {
            window.scrollTo(0, 0);
        }
    }, [action, pathname]);

    useEffect(() => {
        let title = "";
        let metaDescription = "";

        switch (pathname) {
            case "/":
                title = "";
                metaDescription = "";
                break;
        }

        if (title) {
            document.title = title;
        }

        if (metaDescription) {
            const metaDescriptionTag = document.querySelector(
                'head > meta[name="description"]'
            );
            if (metaDescriptionTag) {
                metaDescriptionTag.content = metaDescription;
            }
        }
        console.log(window.location.pathname)
    }, [pathname]);

    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: location.pathname + location.search, title: "PageView"});
    }, [location]);

    return (
        <>
            <AuthContextProvider>
                <CssBaseline/>
                {(window.location.pathname === "/client/dashboard/") ? (
                    <PositionProvider>
                        <ReactNotifications/>
                        <Routes>
                            <Route path="/Login" element={<Login role={'client'}/>}/>
                            <Route path="/Register" element={<Register/>}/>
                            <Route path="/Comparisons" element={<Comparisons/>}/>
                            <Route path="/CreateInterview" element={
                                <InterviewCreationProvider><InterviewCreation/></InterviewCreationProvider>}/>
                            <Route path="/" element={<Candidates/>}/>

                        </Routes>
                    </PositionProvider>
                ) : (
                    (window.location.pathname === "/candidate/") ? (
                        <>
                            <TawkMessengerReact
                                propertyId={TAWK_PROPERTY_ID}
                                widgetId={'1hnqq2tpj'}
                            />
                            <Routes>
                                <Route path="/login" element={<Login role={'user'}/>}/>
                                <Route path="/roleplay/:interviewAccessCode/:currentQuestionNumber"
                                       element={<RoleplayProvider><Roleplay/></RoleplayProvider>}/>
                                <Route path="/interview/:interviewAccessCode"
                                       element={<InterviewProvider><InterviewBase/></InterviewProvider>}/>
                                <Route path="/signup/:positionAccessCode"
                                       element={<InterviewWizard></InterviewWizard>}/>):null)
                            </Routes>
                        </>
                    ) : <Routes></Routes>)}
            </AuthContextProvider>
        </>
    );

}

export default App;
