import Authentication from "../components/General/Authentication"
import React, {useState} from "react";
import {makePostRequest} from "../api/submit";
import {API, BACKEND, CLIENT} from "../constants";


const Login = ({role}) => {
    const [formError, setFormError] = useState('');

    const formHandleSubmit = (answers) => {
        makePostRequest(`${BACKEND}${API}/login/`, {
            username: answers.username,
            password: answers.password
        }).then(response => {
            const status = response._metadata.status;
            if (status === 200) {
                window.location.href = `${CLIENT}/`
            } else if (status === 401) {
                setFormError('Invalid Username or/and Password')
            } else {
                setFormError('Something went wrong. Please try again later')
            }
        })
    }

    const formValidate = (answers, setErrors) => {
        const newErrors = {};
        questions.forEach(({id, type, required}) => {
            if (!answers[id] && required) {
                newErrors[id] = 'This field is required';
            } else if (type === 'email' && !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,12}$/.test(answers[id])) {
                newErrors[id] = 'Invalid email format';
            }
        });
        setErrors(newErrors);
        return !Object.values(newErrors).length;
    }

    const questions = [
        {
            id: "username",
            label: "Username",
            type: "text",
            required: true
        },
        {
            id: "password",
            label: "Password",
            type: "password",
            required: true
        }
    ]
    return (<Authentication formQuestions={questions} formValidate={formValidate} formHandleSubmit={formHandleSubmit}
                            isLoginPage={true} formError={formError}/>)
};

export default Login;