import React from 'react';
import FileSaver from 'file-saver';
import Cookies from "js-cookie";
import {API, BACKEND, CLIENT} from "../constants";
import {formatJsonStrings} from "../utils/common";
import axios from "axios";

export const getCsrfToken = function () {
    return fetch(`${BACKEND}/client/getCsrf/`)
        .then(response => response.json())
        .then(data => {
            const csrfToken = data.csrfToken;
            Cookies.set('csrftoken', csrfToken);
        })
        .catch(error => {
            console.error('Error fetching CSRF token:', error);
        });
}


export let makePostRequest = async function (uri, requestBody) {
    if (Cookies.get('csrftoken') === undefined) {
        await getCsrfToken();
    }
    if (uri[0] === '/') {
        uri = `${BACKEND}${uri}`
    }
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'X-CSRFToken': Cookies.get('csrftoken')},
        credentials: 'include',
        body: JSON.stringify(requestBody)
    };
    return fetch(uri, requestOptions)
        .then(response => {
            return response.json()
                .then(data => ({
                    _metadata: {
                        status: response.status
                    },
                    ...data
                }))
                .then(data => {
                    return data
                });
        })
        .then(response => {
            console.log(`${uri}:`, response);
            if (response._metadata.status === 401 && response.status === 'Unauthorized') {
                throw new Error('Unauthorized');
            }
            return response;
        })
        .catch(error => {
            if (error.toString() === 'Error: Unauthorized') {
                window.location.href = `${CLIENT}/Login`
            }
        })
}

export let submitAIQuestion = function (request_body) {
    return makePostRequest(`${BACKEND}${API}/submit_ai_question/`, request_body)
};


export let getRoleplayInfo = function (request_body) {
    return makePostRequest(`${BACKEND}${API}/get_roleplay_info/`, request_body).then(response => {
        return response
    })
};

export let processRoleplayReply = function (request_body) {
    return makePostRequest(`${BACKEND}${API}/process_roleplay_reply/`, request_body).then(response => {
        return response
    })
};

export let getInterviewInfo = function (request_body) {
    return makePostRequest(`${BACKEND}${API}/get_interview_info/`, request_body).then(response => {
        return response
    })
};

export let subtractAttempt = function (request_body) {
    return makePostRequest(`${BACKEND}${API}/subtract_attempt/`, request_body).then(response => {
        return response
    })
};

export let submitInterviewVideoBlob = function (blob, data) {
    const postData = new FormData();

    for (const key in data?.fields) {
        postData.append(key, data.fields[key]);
    }
    postData.append('file', blob);

    const requestOptions = {
        method: 'POST',
        headers: {
            //'Content-Type': 'video/webm'
        },
        body: postData
    };
    return fetch(data.url, requestOptions)
        .then(response => {
            console.log(`${data.url}:`, response);
            return response;
        })
};


export let makeHttpPostRequest = async function (uri, requestBody) {
    if (Cookies.get('csrftoken') === undefined) {
        await getCsrfToken();
    }
    if (uri[0] === '/') {
        uri = `${BACKEND}${uri}`
    }
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'X-CSRFToken': Cookies.get('csrftoken')},
        credentials: 'include',
        body: JSON.stringify(requestBody)
    };
    return fetch(uri, requestOptions)  // Endpoint in Django that returns the PDF
        .then((response) => {
            if (response.ok) {
                return response.blob();  // Convert the response to a Blob (binary data)
            } else {
                throw new Error('Failed to download PDF');
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}