import React, { useState, useEffect, useContext } from 'react';
import styles from "./Credentials.module.css";
import PositionContext from "../../contexts/position";
import Cookies from "js-cookie";
import Avatar from 'react-avatar';
import { generateColor, generateTextColor } from '../../utils/avatarColor';
import {BACKEND} from "../../constants";



function Credentials() {
    const [show, setShow] = useState(false);
    const { user, setUser } = useContext(PositionContext);

    const handleLogout = async () => {
        await fetch(`${BACKEND}/client/logout`);
        window.location.href = `/client/dashboard/#/Login`
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (event.target.className === styles.modal) {
                setShow(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    let bgColor = "#FFCDFF"
    user.username ? bgColor = generateColor(user.username) : null;
    return (

        <div className={styles.menuBottomLogin}>
            <div className={styles.avatarParent}>
                <Avatar
                    name={user.username}
                    round={true}
                    color={bgColor}
                    fgColor={generateTextColor(bgColor)}
                    size="48"
                />
                <div className={styles.nameEmail}>
                    <div className={styles.conflictResolution}>
                        {user.username}
                    </div>
                    <div className={styles.email}>
                        {user.email}
                    </div>
                </div>
            </div>
            <button className={styles.dotMenu} id="settings_button" onClick={() => setShow(!show)}>
                <div className={styles.dotMenuChild} />
                <div className={styles.dotMenuChild} />
                <div className={styles.dotMenuChild} />
            </button>
            {show && (
                <div className={styles.modal}>
                    <div className={styles.modalContent}>
                        <button onClick={handleLogout}>Log out</button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Credentials