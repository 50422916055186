import React, {useState, createContext} from 'react';
import useStateCallback from "../modules/stateCallback"

const PositionContext = createContext();

export const PositionProvider = ({children}) => {
    const [position, setPosition] = useState();
    const [data, setData] = useStateCallback([]);
    const [user, setUser] = useState([])


    const handleHeaderButtonClick = (id) => {
        let _data = {...data};
        _data[id.q_type][id.q_num]['hidden'] = !(_data[id.q_type][id.q_num]['hidden'])
        setData(_data);
    };

    return (
        <PositionContext.Provider value={{position, setPosition, data, setData, handleHeaderButtonClick, user, setUser}}>
            {children}
        </PositionContext.Provider>
    );
};

export default PositionContext;